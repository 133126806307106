import React, { FC, useState, useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { Button } from '@/components/ui/Button';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { typography } from '@/components/ui/typography';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  i18nCreateLanguageSubpath,
  i18nReplaceLanguageSubpath,
} from '@/middleware/i18n/i18n.utils';
import { useRouter } from '@/middleware/i18n';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { FastAnswerQuestionBaseFragment } from '@/controllers/faq/generated/fastAnswerQuestionBase.fragment.generated';
import styles from './FAQItem.module.scss';

interface Props {
  item: FastAnswerQuestionBaseFragment;
}

export const FAQItem: FC<Props> = (props) => {
  const { item } = props;
  const [active, setActive] = useState(false);
  const { language, subDomain } = useSubDomainContext();
  const router = useRouter();

  const patchInternalLinks = useCallback((text: string) => {
    let patchedText = text;

    const regex = /href="\/(.*?)"/g;
    const matches = patchedText.match(regex);

    if (matches) {
      matches.forEach((match) => {
        const link = match.replace('href="', '').replace('"', '');
        let newHref = i18nReplaceLanguageSubpath({
          url: link,
          language: i18nCreateLanguageSubpath({
            language,
            domain: subDomain,
          }),
          supportedLanguages: router.locales ?? [DEFAULT_LOCALE],
        });

        if (!newHref.startsWith('/')) {
          newHref = `/${newHref}`;
        }

        const newLink = `href="${newHref}"`;

        patchedText = patchedText.replace(match, newLink);
      });
    }

    return patchedText;
  }, [language, router.locales, subDomain]);

  const toggleModule = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <div className={cn(styles.wrapper, {
      [styles.itemOpened]: active,
      [styles.itemClosed]: !active,
    })}
    >
      <div
        className={styles.questionBlock}
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <div className={styles.questionWrapper}>
          <h4
            className={typography.landingH5}
            itemProp="name"
          >
            {item.question}
          </h4>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            mode={Button.mode.Secondary}
            size={Button.size.Large}
            LeftIcon={IconChevronDown}
            onClick={toggleModule}
            className={styles.button}
          />
        </div>
      </div>

      <div
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        className={styles.answerBlock}
      >
        <div className={styles.answerWrapper}>
          <p
            itemProp="text"
            className={cn(
              typography.paragraph,
              typography.landingTextMain,
              styles.FAQAnswer,
            )}
            dangerouslySetInnerHTML={{
              __html: patchInternalLinks(item.answer),
            }}
          />
        </div>

      </div>

    </div>
  );
};
