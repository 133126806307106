import React, { FC, useState } from 'react';
import YouTube, { Options, YouTubeProps } from 'react-youtube';
import { cn } from '@/lib/classNames';
import { Loader } from '@/components/ui/Loader';
import styles from './VideoPlayer.module.scss';

type Props = YouTubeProps & { autoplay?: boolean };

export const DEFAULT_PLAYER_OPTIONS: Options = {
  height: '472',
  width: '100%',
  playerVars: {
    loop: 1,
    autoplay: 0,
    rel: 0,
    fs: 1,
    iv_load_policy: 3,
    origin: 'https://mate.academy',
  },
};

export const VideoPlayer: FC<Props> = (props) => {
  const {
    className,
    opts = {},
    autoplay = false,
    ...rest
  } = props;

  const [ready, setReady] = useState(false);

  const readyHandler = (event: any) => {
    setReady(true);

    if (autoplay) {
      event.target.playVideo();
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <Loader loading={!ready} />

      <YouTube
        opts={{
          ...DEFAULT_PLAYER_OPTIONS,
          ...opts,
        }}
        onReady={readyHandler}
        className="video-player"
        containerClassName="video-player-container"
        {...rest}
      />
    </div>
  );
};
