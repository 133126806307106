export const getYoutubeId = (link: string) => {
  const rgxp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = link.match(rgxp);

  if (match && match[2]?.length === 11) {
    return match[2];
  }

  return '';
};
