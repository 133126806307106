import { QueryResult } from '@apollo/client';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { FastAnswerQuestionBaseFragment } from '@/controllers/faq/generated/fastAnswerQuestionBase.fragment.generated';
import {
  FastAnswerQuestionsByPagesQuery,
  FastAnswerQuestionsByPagesQueryVariables,
  useFastAnswerQuestionsByPagesQuery,
} from '@/controllers/faq/generated/fastAnswerQuestionsByPages.query.generated';

interface UseCourseLandingFAQItems {
  (options: { pages: string[]; modifier?: string }): [
    FastAnswerQuestionBaseFragment[],
    QueryResult<
      FastAnswerQuestionsByPagesQuery,
      FastAnswerQuestionsByPagesQueryVariables
    >,
  ];
}
export const useFAQItems: UseCourseLandingFAQItems = (options) => {
  const { language } = useSubDomainContext();

  const faqQuery = useFastAnswerQuestionsByPagesQuery({
    variables: {
      lang: language,
      pages: options.pages,
      modifier: options.modifier,
    },
  });

  return [
    faqQuery.data?.fastAnswerQuestionsByPages ?? [],
    faqQuery,
  ];
};
