import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { TextReview } from '@/controllers/reviews/reviews.typedefs';
import { StudentTextReview } from '@/components/reviews-landing/StudentTextReview/StudentTextReview';
import { cn } from '@/lib';
import styles from './TextReviewsSlider.module.scss';

interface Props {
  prevButton: HTMLButtonElement | null;
  nextButton: HTMLButtonElement | null;
  items: TextReview[];
}

export const TextReviewsSlider: FC<Props> = (props) => {
  const {
    prevButton,
    nextButton,
    items,
  } = props;

  return (
    <Swiper
      className={cn(styles.container, 'overflow-visible')}
      modules={[Navigation]}
      navigation={{
        prevEl: prevButton,
        nextEl: nextButton,
      }}
      spaceBetween={24}
      slidesPerView={1}
      speed={500}
      grabCursor
      breakpoints={{
        1200: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 1,
        },
        320: {
          slidesPerView: 1,
        },
      }}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id}>
          <StudentTextReview item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
