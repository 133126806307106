import React, { FC, useCallback } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { IconExternalLink } from '@/components/ui/icons/IconExternalLink';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { typography } from '@/components/ui/typography';
import { analyticsSDK } from '@/controllers/analytics';
import { ReviewButtonClickedSource, ReviewButtonType } from '@/controllers/analytics/generated';
import { TextReview } from '@/controllers/reviews/reviews.typedefs';
import styles from './StudentTextReview.module.scss';

type Props = {
  item: TextReview;
};

export const StudentTextReview: FC<Props> = (props) => {
  const {
    name,
    jobTitle,
    content,
    avatar,
    sourceUrl,
    id,
    createdAt,
  } = props.item;

  const { t } = useTranslation([I18N_CODES.reviews]);

  const handleClick = useCallback(() => {
    analyticsSDK.landing.sendReviewButtonClickedEvent({
      type: ReviewButtonType.ReadOriginal,
      source: ReviewButtonClickedSource.ReviewsPage,
    });
  }, []);

  return (
    <article
      className={cn(styles.item)}
      data-qa='student-text-review'
      itemProp="comment"
      itemScope
      itemType="https://schema.org/Comment"
    >
      <div
        className={cn(styles.user, 'mb-24')}
        itemProp="author"
        itemScope
        itemType="https://schema.org/Person"
      >
        <UserAvatar
          // INFO: reviewId is used as userId because we don't want to receive user id only for this component
          userId={id}
          fullName={name}
          avatar={avatar}
          size={64}
          shouldShowBadgeIcon={false}
          shouldOpenProfile={false}
        />

        <div className={styles.userInfo}>
          <div
            className={typography.landingH4}
            data-qa='student-text-review-name'
            itemProp="name"
          >
            {name}
          </div>

          {jobTitle && (
            <span
              className={cn(styles.jobTitle, typography.landingTextMain)}
              data-qa='student-text-review-job-title'
              itemProp="jobTitle"
            >
              {jobTitle}
            </span>
          )}
        </div>
      </div>

      <div
        className={cn(styles.reviewContent, typography.landingTextMain)}
        data-qa='student-text-review-content'
        itemProp="text"
      >
        {content}
      </div>

      <meta itemProp="dateCreated" content={new Date(createdAt).toISOString()} />

      <div className={styles.filler} />

      {sourceUrl && (
        <Button
          size={Button.size.Large}
          mode={Button.mode.Secondary}
          text={t(`${I18N_CODES.reviews}:reviews_read_origin_button`)}
          RightIcon={IconExternalLink}
          href={sourceUrl}
          onClick={handleClick}
          isInternalLink={false}
          target="_blank"
          rel="nofollow noreferrer noopener"
          className="mt-32 small-mt-24"
          hasFullWidth
          dataQa="student-text-review-button"
        />
      )}
    </article>
  );
};
