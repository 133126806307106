import { useMemo } from 'react';
import { QueryResult } from '@apollo/client';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  GetReviewsQuery,
  GetReviewsQueryVariables,
  useGetReviewsQuery,
} from '@/controllers/reviews/graphql/generated/getReviews.query.generated';
import { ReviewFragment } from '@/controllers/reviews/graphql/generated/review.fragment.generated';

interface UseCourseLandingReviewItems {
  (options?: { courseId?: number }): [
    ReviewFragment[],
    QueryResult<
      GetReviewsQuery,
      GetReviewsQueryVariables
    >,
  ];
}
export const useReviews: UseCourseLandingReviewItems = (options?) => {
  const {
    courseId,
  } = options || {};

  const { language, subDomain } = useSubDomainContext();

  const reviewsQuery = useGetReviewsQuery({
    variables: {
      courseId,
      language,
      domain: subDomain,
    },
  });

  const reviews = useMemo(
    () => reviewsQuery.data?.reviews ?? [],
    [reviewsQuery.data?.reviews],
  );

  return [reviews, reviewsQuery];
};
