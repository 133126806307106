import React, { FC, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { YouTubeProps } from 'react-youtube';
import { cn } from '@/lib/classNames';
import { DEFAULT_PLAYER_OPTIONS } from '@/components/platform/VideoPlayer';
import { getYoutubeId } from '@/components/platform/VideoPlayer/VideoPlayer.utils/getYoutubeId';
import { typography } from '@/components/ui/typography';
import { Image } from '@/components/ui/Image';
import { Button } from '@/components/ui/Button';
import { IconPlayFill } from '@/components/ui/icons/IconPlayFill';
import styles from './VideoReviewItem.module.scss';

const VideoPlayer = dynamic<YouTubeProps>(
  import('@/components/platform/VideoPlayer')
    .then((mod) => mod.VideoPlayer),
);

export interface VideoReview {
  id: number;
  username: string;
  jobTitle?: string;
  videoLink: string;
  posterLink?: string;
}

interface Props {
  item: VideoReview;
}

export const VideoReviewItem: FC<Props> = (props) => {
  const { item } = props;

  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const onClick = useCallback(() => {
    setPlay(true);
    setLoading(true);
  }, []);

  const onPlayerLoaded = useCallback(() => {
    setLoading(false);
    setReady(true);
  }, []);

  return (
    <div>
      <div className={cn(styles.mediaWrapper, 'mb-16')}>
        {play && (
          <VideoPlayer
            className={styles.videoWrapper}
            videoId={getYoutubeId(item.videoLink)}
            onReady={onPlayerLoaded}
            opts={{
              width: '267px',
              height: '468px',
              playerVars: {
                ...DEFAULT_PLAYER_OPTIONS.playerVars,
                autoplay: 1,
              },
            }}
          />
        )}

        {!ready && (
          <>
            <div className={styles.imageWrapper}>
              {/* TODO: remove 'unoptimized' when url added */}
              <Image
                src={item.posterLink ?? 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'}
                unoptimized={!item.posterLink}
                width={267}
                height={468}
                objectFit="cover"
              />
            </div>

            <Button
              size={Button.size.Large}
              mode={Button.mode.BrandPrimary}
              LeftIcon={IconPlayFill}
              onClick={onClick}
              isLoading={loading}
              disabled={loading}
              className={cn(styles.playButton, {
                [styles.playButtonLoading]: loading,
              })}
            />
          </>
        )}

      </div>

      <p className={cn(typography.landingH5, 'mb-8')}>
        {item.username}
      </p>

      <p className={cn(typography.landingTextMain, styles.jobTitle)}>
        {item.jobTitle}
      </p>
    </div>
  );
};
